<template>
    
    <div class="fav-modal display-flex-center" v-show="isShowFavModal">
        <Transition :name="animationName()">
            <div class="fav-modal__body" v-show="isShowFavModal" @click="handleContent($event)" >
                <div v-show="currentStep === 0" class="fav-modal__walkthrough">
                    <div class="walkthrough-intro__body-left">
                        <h1 class="walkthrough-intro__body-left__title1">{{ $t('NEW_FEATURES') }}</h1>
                        <h2 class="walkthrough-intro__body-left__title2">{{ $t('FAVOURITE_NOTIFICATION') }}</h2>
                        <div class="walkthrough-intro__body-left__desc">
                            <p>{{ $t('WALkTHROUGH_DESC') }}</p>
                            <!-- <p>{{ $t('WALkTHROUGH_DESC_2') }}</p> -->
                        </div>
                        <div class="walkthrough-intro__body-right__img-mobile" >
                            <img class="w-100" src="../../../static/images/walkthrough_2.png">
                        </div>
                        <button class="walkthrough-intro__body-left__explore" @click="handleExplore">{{ $t('EXPLORE_NOW') }}</button>
                    </div>
                    <img class="walkthrough-intro__body-right__img" src="../../../static/images/walkthrough_1.png">
                </div>
                <div v-show="currentStep > 0" class="fav-modal__walkthrough2" >
                    <!-- bottom {{bottom}} top {{top}} height {{innerHeight}} -->
                    <div class="walkthrough-tab__wrapper">
                        <div class="walkthrough-tab" :class="{'selected': o.isSelected}" v-for="(o, index) in info" :key="index"></div>
                    </div>
                    <div class="walkthrough-title__wrapper" :class="{'walkthrough-title__wrapper-hide': isScrollTop}">
                        <h1 class="walkthrough-title">{{ $t(currentSelectedStep.title) }}</h1>
                        <h2 class="walkthrough-desc">
                            <template v-if="currentSelectedStep.type !== 'NOTIFICATION'">
                                <span>{{ clickOrTapText }}</span>
                                <img class="walkthorugh-fav-star__text" src="../../../static/images/icons/icon-star-grey.png" alt=""/>
                            </template>
                            <span>{{ $t(currentSelectedStep.desc) }}</span>
                        </h2>
                    </div>
                        <template v-if="currentSelectedStep.type === 'NOTIFICATION'">
                        <div class="fav-model__body-list__wrapper" :class="{ 'scrollbar-show': !isMobile }">
                            <div class="noti-preference__wrapper-list mt-15rem">
                                <!-- @notiPreferenceEnabled="enableNextButton"  -->
                                <Preference 
                                    v-if="!isMobile" 
                                    :showHeader="true" 
                                    :isWalkthrough="true" 
                                    :class="{ 'scrollbar-show': !isMobile }" 
                                    ref="prefrenceRef"
                                ></Preference>
                                <!-- @notiPreferenceEnabled="enableNextButton"  -->
                                <Preference 
                                    v-else-if="isMobile" 
                                    :isWalkthrough="true" 
                                    ref="prefrenceRef"
                                ></Preference>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <!-- Search Box -->
                        <div class="search-wrapper">
                            <img class="search-icon" src="../../../static/images/icons/icon_search.png" alt=""/>
                            <input
                                autofocus
                                class="search-input"
                                type="text"
                                :placeholder="$t('SEARCH')"
                                v-model="userInput"
                                @input="handleSearchUserInput"
                                @focus="handleToShowTitle('focus')"
                                @blur="handleToShowTitle('blur')"
                                ref="userInputRef"
                            />
                            <div class="search-clear display-flex-center"  @click="handleClearUserInput">            
                                <svg
                                    width="11"
                                    height="10"
                                    viewBox="0 0 11 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10.2992 1.16705L9.33236 0.200195L5.49922 4.03334L1.66608 0.200195L0.699219 1.16705L4.53236 5.0002L0.699219 8.83334L1.66608 9.80019L5.49922 5.96705L9.33236 9.80019L10.2992 8.83334L6.46608 5.0002L10.2992 1.16705Z"
                                    fill="#E7ECEE"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div  
                        id="listId"
                            class="fav-model__body-list__wrapper" 
                            ref="favContentListRef" 
                            @scroll="handleFavContentScrollEvent($event)" 
                            :class="{ 'scrollbar-show': !isMobile }"
                        >
                            <div class="display-flex flex-col">
                                <div class="fav-modal__subtitle">
                                    <p v-if="isNoData">{{ $t("NO_RESULT") }}</p>
                                    <p v-else>{{ userInput === '' ? $t(currentSelectedStep.defaultTitle) :  $t(currentSelectedStep.searchTitle) }}</p>	
                                </div>
                                <div class="fav-modal__modal-card-wrapper fav-modal__wrapper-list fix-h display-flex flex-col">
                                    <FavCard
                                        v-for="c in favResultList"
                                        :key="c.id"
                                        :keyName="c.key"
                                        :type="tabName"
                                        :countryKey="c.countryKey"
                                        :name="c.name"
                                        :id="c.id"
                                        :logo="c.logo"
                                        :isFav="c.isFav"
                                        :object="c"
                                        isDisableRedirect=true						
                                        @favHandler="favHandler"
                                    />
                                </div>
                            </div>
                            
                        </div>
                   </template>
                    <div class="walkthrough-btn__wrapper">
                        <button class="walkthrough-btn" @click="handleNextBtn" :class="{'selected': currentSelectedStep.isButtonEnable}">{{ $t(currentSelectedStep.buttonText) }}</button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Preference from "@/views/notifications/components/Preference.vue"
import FavCard from "@/views/fav/components/FavCard.vue";
const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;
export default {
    components: { 
        FavCard,
        Preference,
    },
    data() {
        return {
            isShowFavModal: false,
            currentStep: 0,

            userInput: "",
            favResultList:[],

            info: [
                {
                    title: 'WALKTHROUGH_FAVOURITE_TEAM',
                    desc: 'WALKTHROUGH_FAVOURITE_TEAM_DESC',
                    defaultTitle: 'POPULAR_TEAMS',
                    searchTitle: 'TEAMS', 
                    type: 'TEAMS',
                    isSelected: false,
                    isButtonEnable: false
                },
                {
                    title: 'WALKTHROUGH_FAVOURITE_COMPETITIONS',
                    desc: 'WALKTHROUGH_FAVOURITE_COMPETITIONS_DESC',
                    defaultTitle: 'POPULAR_COMPETITIONS',
                    searchTitle: 'COMPETITIONS', 
                    type: 'COMPETITIONS',
                    isSelected: false,
                    isButtonEnable: false
                },
                {
                    title: 'NOTIFICATION_PREFERENCES',
                    desc: 'YOU_CAN_DECIDE_WHAT_NOTIFICATIONS_YOU_WANT_TO_RECEIVE',
                    type: 'NOTIFICATION',
                    isSelected: false,
                    isButtonEnable: false
                },
                // {
                //     title: 'WALKTHROUGH_FAVOURITE_NOTIFICATION',
                //     desc: 'WALKTHROUGH_FAVOURITE_NOTIFICATION_DESC',
                //     defaultTitle: "",
                //     searchTitle: "",
                //     isSelected: false,
                //     isButtonEnable: false
                // }
            ],

            currentSelectedStep: {
                title: '',
                desc: '',
                buttonText: '',
                searchTitle: '',
                defaultTitle: '',
                type: '',
                isButtonEnable: false
            },

            isNoData: false,

            storeSelectedFavList: {
                leagueIds: [],
                teamIds: []
            },

            defaultBtnText: "NEXT_WALKTHROUGH",
            lastStepBtnText: "FINISH",
            clickOrTapText: "",

            isShowTitle: true,
            isScrollTop: false,
            bottom: 0,
            top:0,
            innerHeight: 0
        }
    },
    computed: {
        ...mapGetters([
            "isLogin", 
            "userInfo", 
            "isMobile", 
        ]),
    },
    mounted() {
        this.commonVerifyLogin();
        this.init();
    },
    beforeUnmount() {
       
    },
    methods: {
        ...mapActions([
            "toastrChangeEvent",
            "setUserData",
            "commonVerifyLogin",
            
            "getRetrieveUserInfo",
            "getTeamPopular",
            "getCompetitionPopular",
            "getTeamSearch",
            "getCompetitionSearch",

            "favLoginTipsChangeEvent",
            "toastrFavChangeEvent",
            
            "unFavTeam",
            "addFavTeam",
            "unFavLeague",
            "addFavLeague",
            "addWalkthrough"
        ]),
        init() {
            if (this.isLogin) {           
                if (!this.userInfo.walkthroughFlag) {
                    // Enable on basketball Phase 2
                    // setTimeout(() => {
                    //     this.isShowFavModal = true;
                    //     this.clickOrTapText = this.isMobile ? this.$t("TAP") : this.$t("CLICK_ON");
                    //     this.$nextTick(() => {
                    //         document.body.style.overflow = "hidden" 
                    //     })  
                    // }, 300);
                                        
                } 
            }
        },
        keyboardHideHandler() {
            alert("s")
        },
        handleContent(e) {
            //e.stopPropagation();
        },

        handleExplore() {         
            this.currentSelectedStep = this.info[this.currentStep];
            this.info[this.currentStep].isSelected = true;

            this.currentSelectedStep.buttonText = this.defaultBtnText;
            this.currentStep++;

            if (!this.isMobile) {
                this.$nextTick(() => {
                    this.$refs.userInputRef.focus();
                })
            }
           
            this.getSelectedFavPopularData();
        },

        handleClearUserInput() {
            this.resetSearchBox();
            this.$refs.userInputRef.focus();
        },

        handleSearchUserInput() {		
            this.userInput = this.$refs.userInputRef.value;

            if (this.userInput === "") {	
                this.getSelectedFavPopularData();	
            } else {
                this.getSearchListData(this.userInput);
                this.$refs.favContentListRef.scrollTo(0,0)
            }
        },

        getSelectedFavPopularData() {
           if (this.currentSelectedStep.type === 'TEAMS') {
                this.getPopularTeamsData();
           } else if (this.currentSelectedStep.type === 'COMPETITIONS') {
                this.getPopularCompetitionsData();
           }
           this.isNoData = false;
        },

        async getPopularTeamsData() {
            let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
                limit: 10
            };

            const result = await this.getTeamPopular(params);
            this.favResultList = result.result;
            
            this.matchBackFavourtList("teamIds")
        },

        async getPopularCompetitionsData() {
            let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
                limit: 10
            };

            const result = await this.getCompetitionPopular(params);
            this.favResultList = result.result;

            this.matchBackFavourtList("leagueIds")
        },

        handleFavContentScrollEvent(event) {
            if (this.isMobile) {
                //this.$refs.userInputRef.blur();. 
            const currentScroll = event.currentTarget.scrollTop;
            if (currentScroll <= 0) {
                console.log("s")
                body.classList.remove(scrollUp);
                return;
            }

            if (currentScroll > lastScroll) {
                //this.bottom = currentScroll
            console.log("bottom",event.currentTarget.scrollTop)

                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
               
                 //this.isScrollTop = true

            } else if (currentScroll < lastScroll ) {   
                     // this.top = currentScroll
                       console.log("top",event.currentTarget.scrollTop)

                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
                //this.isScrollTop = false;
            }
            lastScroll = currentScroll;
                
            } else {
                this.favLoginTipsChangeEvent({isOpen: false});
            }
        },

        async getSearchListData(input) {
            let result;

            let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
                q: input,
            };

            if (this.currentSelectedStep.type === 'TEAMS') {
                result = await this.getTeamSearch(params);
            } else if (this.currentSelectedStep.type === 'COMPETITIONS') {
                result = await this.getCompetitionSearch(params);
            }
          
            this.favResultList = result.result;

            if (this.favResultList.length === 0) {
                this.isNoData = true;
            } else {
                this.isNoData = false;
                this.matchBackFavourtList()
            }
        },

        matchBackFavourtList(favTeam) {
            if (!favTeam) {
                if (this.currentSelectedStep.type === 'TEAMS') {
                    favTeam = "teamIds"
                } else if (this.currentSelectedStep.type === 'COMPETITIONS') {
                    favTeam = "leagueIds"
                }
            }
            
            if (this.storeSelectedFavList[favTeam].length > 0) {

                this.favResultList.find( x=> {
                    this.storeSelectedFavList[favTeam].find( y=> {
                        if (y === x.id) {
                            x.isFav = true
                        } 
                    })
                })
            }
        
        },
        async favHandler(id, isFav, titleType, countryName, type, object) {
            object.isFav = !object.isFav;
            
            let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
            };

            if (this.currentSelectedStep.type === 'TEAMS') {
                params = { ...params, teamId: id };   
                 this.handleStoreFavList(!isFav, id, "teamIds")        
            } else if (this.currentSelectedStep.type === 'COMPETITIONS'){
                params = { ...params, leagueId: id };
                this.handleStoreFavList(!isFav, id, "leagueIds")
            }
        },

        async handleNextBtn() {
            //last step
            if (this.currentStep === this.info.length) {

                this.storeSelectedFavList.leagueIds = this.storeSelectedFavList.leagueIds.toString();
                this.storeSelectedFavList.teamIds = this.storeSelectedFavList.teamIds.toString();

                const result = await this.addWalkthrough(this.storeSelectedFavList);

                if (result.result.businessCode === 0) {
                    this.getRetrieveUserInfoData();
                    this.$emit("closeWalkThroughModal");
                    this.isShowFavModal = false;
                } else {
                    let params = {
                        isOpen: true,
                        msg: result.result.message,
                    };

                    this.toastrChangeEvent(params);
                }
                
                
                document.body.style.overflow = "unset"
                // document.body.style.position = "initial"

            } else {
                this.currentSelectedStep = this.info[this.currentStep];
                if (this.currentSelectedStep.type === "NOTIFICATION") {
                    this.$nextTick(() => {
                        this.$refs.prefrenceRef.toggleSetting();
                        this.enableNextButton();
                    })
                }
                // bind next button text
                if (this.currentStep === this.info.length - 1) {
                    this.currentSelectedStep.buttonText = this.lastStepBtnText;
                } else {
                    this.currentSelectedStep.buttonText =  this.defaultBtnText
                }

                this.info.forEach(element => {
                    element.isSelected = false;
                });
                this.$refs.favContentListRef.scrollTo(0,0)
                this.info[this.currentStep].isSelected = true;
                this.currentStep++;

                this.resetSearchBox();
            }        
        },

        // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
            };
            const result = await this.getRetrieveUserInfo(params);
            this.setUserData(result.result.data);
        },

        resetSearchBox() {
            //reset search box
            this.userInput = "";

            this.getSelectedFavPopularData();
        },

        handleStoreFavList(isFav, id, favItem) {

            if (isFav) {
                this.storeSelectedFavList[favItem].push(id);
                
            } else {
                const index = this.storeSelectedFavList[favItem].indexOf(id);
                this.storeSelectedFavList[favItem].splice(index, 1);
            }
            

            if(this.storeSelectedFavList[favItem].length === 0) {
                this.currentSelectedStep.isButtonEnable = false
            } else {
                this.currentSelectedStep.isButtonEnable = true
            }
        },

        enableNextButton() {
            this.currentSelectedStep.isButtonEnable = true
        },

        toastrEvent(res, isFav, countryName, id, object) {
            let paramsToastr = {
                isOpen: true,
                msg: countryName,
                isAddedFav: isFav ? false : true,
            };

            this.toastrFavChangeEvent(paramsToastr);
        },

        handleToShowTitle(type) {
            if (this.isMobile) {
                if (type === 'focus') {
                    //this.isShowTitle = false;
                } else {
                    //this.isShowTitle = true;
                }
            }
        },
        animationName() {
            return this.isMobile ? '' : 'slideUp'
        }

    },
    
}
</script>
<style scoped>
.fav-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 32, 32, 0.75);
  z-index: 10;
}
.fav-modal__wrapper {
  background: #000000;
  border-radius: 8px;
  width: 50rem;
  height: 31.5rem;
  padding: 1.5rem 0 4rem 0;
}
.fav-modal__inner-wrapper {
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
  padding-right: 0.5rem;
}
.fav-modal__wrapper-list {
  width: 100%;
  height: 18rem;
  overflow-y: auto;
  padding-right: 0.25rem;
}
.noti-preference__wrapper-list {
  width: 100%;
  height: 25rem;
  overflow-y: auto;
  padding-right: 0.25rem;
}
.search-wrapper {
  width: 100%;
  background: #35454d;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0 1rem 0;
}
.search-clear {
  width: 1.25rem;
  height: 1.25rem;
  background: #212b30;
  border-radius: 50%;
  cursor: pointer;
}
.fav-modal__subtitle {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.5rem 0 0.625rem 0;
}
.fav-modal__modal-card-wrapper {
  gap: 0.5rem;
}
.fav-modal__close-wrapper {
  width: 94%;
  margin: 0 auto;
}
.fav-modal__close {
  background: url("../../../static/images/icons/icon_close_white.png")
    no-repeat center center;
  background-size: 50%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}
.fav-modal__wrapper-list::-webkit-scrollbar,
.noti-preference__wrapper-list::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
}
.fav-modal__wrapper-list::-webkit-scrollbar-thumb,
.noti-preference__wrapper-list::-webkit-scrollbar-thumb {
  background: #4b616c;
  border-radius: 4px;
}
.fav-modal__wrapper-list::-webkit-scrollbar-track,
.noti-preference__wrapper-list::-webkit-scrollbar-track {
  background: #212b30;
  border-radius: 4px;
}
.fav-modal__wrapper-list.scrollbar-show,
.noti-preference__wrapper-list.scrollbar-show {
  padding-right: 0.25rem;
  width: calc(100% + 0.25rem);
}


.fav-model__back{
	margin-right: .9rem;
	display: flex;
	align-items: center;
}

.fav-modal__body{
    border-radius: 8px;
    width: 50rem;
    overflow: hidden;
}
.fav-modal__walkthrough{
    width: 100%;
    height: 100%;
    background: linear-gradient(162.46deg, #35454D 0%, #000000 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3.5rem;
    box-sizing: border-box;
    position: relative;
    transition: all .3s;
}
.slide-left-out{
    transform: translateX(-800px);
}
.slide-left-in {
 transform: translateX(0);
}
.fav-modal__walkthrough2{
    width: 100%;
    height: 100%;
    background: #000000 100%;
    display: flex;
    justify-content: flex-start;
    padding: 2.5rem 6.5rem;
    height: 42.375rem;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    transition: all .3s;
}

.walkthrough-intro__body-left{
    display: flex;
    flex-direction: column;
    height: 370px;
    justify-content: center;
    width: 60%;
}
.walkthrough-intro__body-left__title1{
    font-weight: 700;
    color: white;
    font-size: 3.3125rem;
}
.walkthrough-intro__body-left__title2{
    font-weight: 700;
    color: white;
    font-size: 2.125rem;
}
.walkthrough-intro__body-left__desc{
    margin-top: 4.5rem;
    color: var(--color-grey-light);
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 3rem;
}
.walkthrough-intro__body-left__explore{
    width: 22.5rem;
    background-color: var(--bg-selected);
    padding: 0.625rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    border: none;
    color: black;
    border-radius: .5rem;
}
.walkthrough-intro__body-right__img{
    position: absolute;
    right: 0;
}
.walkthrough-intro__body-right__img-mobile{
    display: none;
}
.walkthrough-tab__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.5rem;
}
.walkthrough-tab{
    width: 5rem;
    height: .5rem;
    background-color: #35454D;
    border-radius: .5rem;
}
.walkthrough-tab.selected{
    background-color: var(--bg-selected)
}
.walkthrough-title{
    font-weight: 700;
    font-size: 2.125rem;
    margin-bottom: 0.875rem;
}
.walkthrough-desc{
    color: #5C7784;
    font-weight: 400;
    font-size: 1rem;
}
.walkthrough-btn{
    background-color: #35454D;
    pointer-events: none;
    color: #5C7784;
    font-size: 0.875rem;
    width: 100%;
    padding: 0.625rem;
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    font-weight: bold;
    border: none;
}
.walkthrough-btn.selected{
    background-color: var(--bg-selected);
    color: black;
    cursor: pointer;
    pointer-events: fill;
}
.walkthorugh-fav-star__text {
    margin: 0 0.15rem;
    position: relative;
    top: 3px;
}
@media (max-width: 768px) {
    .walkthrough-tab__wrapper{
        margin-bottom: 1.5rem
    }
    .fav-modal__wrapper {
        width: 100%;
        height: 100%;
        padding: unset;
    }
    .fav-modal__inner-wrapper {
        width: 90%;
        padding: unset;
    }

    .fav-modal__close {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left: 1rem;
    }
    .fav-modal__close-wrapper {
        width: unset;
        padding: 0.5rem 1rem;
        position: relative;
    }
    .search-wrapper {
        height: 3rem;
        margin: 0.5rem auto;
    }
    .fav-modal__subtitle {
        margin: 1rem 0;
    }
    .fav-modal__close-title {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .fav-modal__btn-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5rem;
        background: #000;
    }
    .fav-modal__btn {
        background: #01c3f7;
        border-radius: 4px;
        padding: 0.625rem 0.75rem;
        border: none;
        font-weight: 700;
        width: 90%;
        color: black !important;
    }
    .has-btn {
        padding-bottom: 12rem;
    }
    .fav-modal__wrapper-list,
    .noti-preference__wrapper-list {
        /* max-height: calc(100vh - 23rem);
        height: calc(100vh - 21.5rem); */
        height: auto
    }
    .fav-modal__wrapper-list::-webkit-scrollbar,
    .noti-preference__wrapper-list::-webkit-scrollbar {
        width: 0;
    }
  
    .walkthrough-intro__body-left__title1{
        font-size: 2.125rem;
    }
    .walkthrough-intro__body-left__title2{
        font-size: 1.375rem;
    }
    .walkthrough-intro__body-left__desc{
        font-size: 0.6875rem;
        margin-top: 1rem;
        margin-bottom: 0;
    }
    .fav-modal__walkthrough{
        padding: 2.5rem 2rem 3rem 2rem;
        display: block;
    }
    .walkthrough-intro__body-right__img{
        display: none;
    }
    .walkthrough-intro__body-left__explore{
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .walkthrough-intro__body-right__img-mobile{
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2.5rem;
    }
    .walkthrough-intro__body-left{
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
    }
    .fav-modal__walkthrough2{
        padding: 1.5rem 1rem;
        height: 100%;
    }
    .walkthrough-title{
        font-size: 1.375rem;
    }
    .walkthrough-desc{
        font-size: 14px;
        font-weight: 400;
    }
    .walkthrough-btn__wrapper{
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 1.5rem;
        display: flex;
        padding: 0 1rem;
        box-sizing: border-box;
    }
    .fav-modal__body{
        width: 100%;
        height: 100%;
    }
    .fav-model__body-list__wrapper{
        overflow-y: auto;
        margin-bottom: 4rem;
        overflow-x: hidden;
        overscroll-behavior: none;
    }
    .walkthrough-title__wrapper{
        transition: transform 0.4s;
    }
    .walkthrough-title__wrapper-hide{
        /* transform: translate3d(0, -100%, 0); */
        display: none
    }
}
</style>
